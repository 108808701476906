import {
  type RouteLocationNormalized,
  type NavigationGuardNext,
} from "vue-router";

import { localeRootPath } from "@/services/router";
import { useProducts } from "@/services/products";

// Middleware:
// Este middleware utiliza meta title das rotas e os adiciona no title da página
export default async (
  to: RouteLocationNormalized,
  _from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  if (to.name !== "checkout") {
    return next();
  }

  const { fetchAll } = useProducts();
  const { data, error } = await fetchAll();

  if (error.value) {
    return next(localeRootPath());
  }

  const plans = data.value?.flatMap((p) => p.plans).map((p) => p.slug) || [];

  // Caso o plano não exista, redireciona para a página inicial
  if (!plans.includes(to.params.id as string)) {
    return next(localeRootPath());
  }

  next();
};
