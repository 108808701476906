import sortBy from "lodash/sortBy";

import { useApi } from "@/services/api";
import { useGraduationStore } from "@/stores/graduations";

export function useGraduations() {
  const store = useGraduationStore();

  function getStudentGraduations(course: Course) {
    return store.me.filter(
      (graduation) => graduation.course.slug === course.slug
    );
  }

  function getMaxGraduationFromCourse(course: Course) {
    const graduations = getStudentGraduations(course);
    const ordered = sortBy(graduations, "graduation.position");

    return ordered.find(
      ({ student_graduation }) => student_graduation.status === "graduated"
    );
  }

  async function fetchGraduations() {
    const { data, error, isFetching } = await useApi(
      "/student/v1/graduations"
    ).json();

    if (!error.value) {
      store.setGraduations(data.value);
    }

    return {
      data,
      error,
      isFetching,
    };
  }

  async function fetchMeGraduations() {
    const { data, error, isFetching } = await useApi(
      "/student/v1/me/graduations"
    ).json();

    if (!error.value) {
      store.setMyGraduations(data.value);
    }

    return {
      data,
      error,
      isFetching,
    };
  }

  return {
    store,
    fetchGraduations,
    fetchMeGraduations,
    getStudentGraduations,
    getMaxGraduationFromCourse,
  };
}

export function useGraduationProgress(progress: GraduationProgress) {
  function getRuleKeys() {
    const rules = {} as { [key: string]: boolean | number };

    Object.entries(progress.graduation.rules).forEach(([key, value]) => {
      if (typeof value === "number" && value > 0) {
        rules[key] = value;
      }

      if (value) {
        rules[key] = value;
      }
    });

    return Object.keys(rules);
  }

  function isRuleCompleted(key: string) {
    const ruleValue =
      progress.graduation.rules[key as keyof typeof progress.graduation.rules];

    const archimmentValue =
      progress.student_graduation.achievements[
        key as keyof typeof progress.student_graduation.achievements
      ];

    if (typeof ruleValue === "number") {
      return Number(archimmentValue) >= Number(ruleValue);
    }

    return ruleValue === archimmentValue;
  }

  function isCompleted() {
    return getRuleKeys().every((rule) => isRuleCompleted(rule));
  }

  function getTotalCompleted() {
    const rules = getRuleKeys();
    return rules.filter((rule) => isRuleCompleted(rule)).length;
  }

  return {
    getRuleKeys,
    getTotalCompleted,
    isRuleCompleted,
    isCompleted,
  };
}
