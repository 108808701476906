import { type Ref, computed, isRef, toRef } from "vue";

import { useApi } from "@/services/api";
import { useCurrentUser } from "@/stores/user";

export function useSubscriptions() {
  const currentUser = useCurrentUser();

  async function fetchSubscriptions() {
    if (currentUser.subscriptions.length) {
      return {
        data: toRef(currentUser.subscriptions),
        error: toRef(null),
        isFetching: toRef(false),
      };
    }

    const { data, error, isFetching } = await useApi(
      "/student/v1/me/subscriptions"
    ).json<Subscription[]>();

    if (!error.value && data.value?.length) {
      currentUser.setSubscriptions(data.value);
    }

    return {
      data,
      error,
      isFetching,
    };
  }

  async function cancelSubscription(subscriptionId: string | number) {
    const { error, isFetching } = useApi(
      `/student/v1/me/subscriptions/${subscriptionId}`
    ).delete();

    return {
      error,
      isFetching,
    };
  }

  return {
    fetchSubscriptions,
    cancelSubscription,
  };
}

export function useSubscription(data: Ref<Subscription> | Subscription) {
  const subscription = computed(() => (isRef(data) ? data.value : data));

  const paymentMethod = computed(() => {
    switch (subscription.value.payment_method_type) {
      case "credit_card":
        return "Cartão de crédito";
      case "bank_slip":
        return "Boleto";
      case "pix":
        return "Pix";
    }
  });

  const paymentStatus = computed(() => {
    switch (subscription.value.status) {
      case "waiting_payment":
        return "Aguardando pagamento";
      case "active":
        return "Ativo";
      case "inactive":
        return "Cancelado";
      case "archived":
        return "Arquivado";
      case "expired":
        return "Expirado";
      case "subscription_failed":
        return "Falha no pagamento";
    }
  });

  const totalPrice = computed(
    () => Number(subscription.value.price.cents) / 100.0
  );

  const discount = computed(() =>
    subscription.value.discount?.cents
      ? Number(subscription.value.discount.cents) / 100.0
      : 0.0
  );

  const finalPrice = computed(() => {
    return totalPrice;
  });

  function cancelSubscription() {
    return useSubscriptions().cancelSubscription(subscription.value.id);
  }

  return {
    subscription,
    paymentMethod,
    paymentStatus,
    discount,
    totalPrice,
    finalPrice,
    cancelSubscription,
  };
}
