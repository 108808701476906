import {
  type RouteLocationNormalized,
  type NavigationGuardNext,
} from "vue-router";

import { useSiteStore } from "@/stores/site";

// Middleware:
// Este middleware utiliza meta title das rotas e os adiciona no title da página
export default (
  to: RouteLocationNormalized,
  _from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const siteStore = useSiteStore();
  siteStore.pageTitle = to.meta?.title;
  next();
};
