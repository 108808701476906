import { createRouter, createWebHashHistory } from "vue-router";

import AboutPage from "@/pages/AboutPage.vue";
import WelcomePage from "@/pages/WelcomePage.vue";
import ComponentsPage from "@/pages/ComponentsPage.vue";

const router = createRouter({
  history: createWebHashHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/",
      name: "welcome",
      component: WelcomePage,
      meta: {
        title: "Início",
      },
    },
    {
      path: "/comecar",
      name: "start",
      component: () => import("@/pages/GettingStarted.vue"),
      meta: {
        layout: "start",
        start: true,
      },
      children: [
        {
          path: "",
          name: "start-index",
          component: () => import("@/pages/getting-started/IndexPage.vue"),
        },
        {
          path: "configurar-ambiente",
          name: "start-select-theme",
          component: () =>
            import("@/pages/getting-started/DiscoveryPlataform.vue"),
        },
        {
          path: "trilha",
          name: "start-trip",
          component: () => import("@/pages/getting-started/StartTripPage.vue"),
        },
        {
          path: "trilha/selecionar",
          name: "select-trip",
          component: () => import("@/pages/getting-started/SelectTrip.vue"),
        },
        {
          path: "curso/selecionar",
          name: "select-course",
          component: () => import("@/pages/getting-started/SelectCourse.vue"),
        },
        {
          path: "entrar-na-plataforma",
          name: "start-enter-platform",
          component: () =>
            import("@/pages/getting-started/ReadyPlatformPage.vue"),
        },
      ],
    },
    {
      path: "/dashboard",
      name: "dashboard",
      component: () => import("@/pages/DashboardPage.vue"),
      meta: {
        title: "Todos os Cursos",
        layout: "dashboard",
      },
      children: [
        {
          path: "",
          name: "dashboard-index",
          component: () => import("@/pages/dashboard/IndexPage.vue"),
          meta: {
            preloading: "dashboard-index",
          },
        },
        {
          path: "curso/:id",
          name: "dashboard-course",
          component: () => import("@/pages/dashboard/CoursePage.vue"),
          meta: {
            preloading: "dashboard-course",
          },
          children: [
            {
              path: "",
              name: "dashboard-course-index",
              component: () => import("@/pages/dashboard/CourseDetailPage.vue"),
              meta: {
                layout: "application",
                hideNavbar: true,
              },
            },
            {
              path: ":lessonModuleId/topicos/:topicId/aulas/:lessonId",
              name: "dashboard-lesson",
              component: () => import("@/pages/LessonPage.vue"),
              meta: {
                layout: "application",
                hideNavbar: true,
                preloading: "lesson",
              },
            },
          ],
        },
      ],
    },
    {
      path: "/comunidade",
      name: "community",
      component: () => import("@/pages/community/IndexPage.vue"),
      meta: {
        title: "Projetos",
        layout: "dashboard",
      },
    },
    {
      path: "/perfil",
      name: "profile",
      meta: {
        title: "Perfil",
        layout: "dashboard",
      },
      component: () => import("@/pages/ProfilePage.vue"),
      children: [
        {
          path: "",
          name: "profile-index",
          component: () => import("@/pages/profile/IndexPage.vue"),
          meta: {
            menu: true,
            aside: "profile",
          },
        },
        {
          path: "graduacoes",
          name: "profile-graduations",
          component: () => import("@/pages/profile/GraduationsPage.vue"),
          meta: {
            menu: true,
          },
        },
        {
          path: "/assinar-plano",
          name: "profile-subscribe",
          component: () => import("@/pages/profile/RenewSubscriptionPage.vue"),
          meta: {
            title: "Assinar Plano",
            layout: "dashboard",
            menu: false,
            aside: "profile",
            checkout: true,
          },
        },
      ],
    },
    {
      path: "/checkout/:id",
      name: "checkout",
      component: () => import("@/pages/checkout/IndexPage.vue"),
      meta: {
        title: "Finalizar Pedido",
        layout: "application",
        checkout: true,
      },
    },
    {
      path: "/checkout/:id/payments/:paymentId",
      name: "checkout-success",
      component: () => import("@/pages/payment/IndexPage.vue"),
      meta: {
        title: "Pagamentos",
        layout: "application",
        checkout: true,
      },
    },
    {
      path: "/assinaturas",
      name: "subscriptions",
      component: () => import("@/pages/subscriptions/IndexPage.vue"),
      meta: {
        title: "Assinaturas",
        layout: "application",
      },
    },
    {
      path: "/convites",
      name: "invitations",
      component: () => import("@/pages/invites/IndexPage.vue"),
      meta: {
        title: "Convites",
        layout: "application",
      },
    },
    {
      path: "/trilha",
      name: "trip",
      component: () => import("@/pages/trip/TripPage.vue"),
      meta: {
        title: "Trilha",
        layout: "trip",
        trip: true,
      },
      children: [
        {
          path: "",
          name: "trip-index",
          component: () => import("@/pages/trip/IndexPage.vue"),
        },
        {
          path: ":id/:lessonModuleId/topicos/:topicId/aulas/:lessonId",
          name: "trip-lesson",
          component: () => import("@/pages/trip/LessonPage.vue"),
          meta: {
            layout: "application",
            preloading: "lesson",
            hideNavbar: true,
          },
        },
      ],
    },
    {
      path: "/sobre",
      name: "about",
      component: AboutPage,
      meta: {
        title: "Sobre",
      },
    },
    {
      path: "/componentes",
      name: "about",
      component: ComponentsPage,
      meta: {
        title: "Componentes",
      },
    },
  ],
});

import metaMiddleware from "@/middlewares/meta";
import gettingStartedMiddleware from "@/middlewares/getting-started";
import profileMiddleware from "@/middlewares/profile";
import subscriptionsMiddleware from "@/middlewares/subscriptions";
import coursesMiddleware from "@/middlewares/courses";
import checkoutMiddleware from "@/middlewares/checkout";
import paymentSuccessMiddleware from "@/middlewares/payment-success";

// Middleware
router.beforeEach(metaMiddleware);
router.beforeEach(gettingStartedMiddleware);
router.beforeEach(profileMiddleware);
router.beforeEach(coursesMiddleware);
router.beforeEach(subscriptionsMiddleware);
router.beforeEach(checkoutMiddleware);
router.beforeEach(paymentSuccessMiddleware);

export default router;
