<template>
  <svg
    viewBox="0 0 24 24"
    width="24"
    height="24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.3741 12.718L19.8801 9.663C20.5811 9.189 21.0001 8.398 21.0001 7.552V7.552C21.0001 6.142 19.8581 5 18.4491 5H5.56608C4.15708 5 3.01508 6.142 3.01508 7.551V7.551C3.01508 8.397 3.43408 9.188 4.13508 9.663L8.64108 12.718C10.6741 14.096 13.3411 14.096 15.3741 12.718V12.718Z"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M3.00012 7.55078V16.9998C3.00012 18.6568 4.34312 19.9998 6.00012 19.9998H18.0001C19.6571 19.9998 21.0001 18.6568 21.0001 16.9998V7.55178"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
