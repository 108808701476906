import { createFetch } from "@vueuse/core";
import { useProfile } from "@/services/profile";

import router from "@/router";

export const useApi = createFetch({
  baseUrl: "/api",
  options: {
    timeout: 10000,
    beforeFetch(ctx) {
      const CSRFToken = document.querySelector("meta[name=csrf-token]");

      ctx.options.headers = {
        ...ctx.options.headers,
        "X-CSRF-Token": CSRFToken?.getAttribute("content") || "",
      };
    },
    async onFetchError(context) {
      const { logout } = useProfile();

      let data = null;

      switch (context.response?.status) {
        case 401:
          await logout();
          break;
        case 403:
          // Redirecionando para página de assinatura de planos
          setTimeout(() => {
            router.push({ name: "profile-subscribe" });
          }, 500);
          break;
      }

      try {
        data = await context.response?.json();
      } catch (e) { }

      return {
        data: null,
        error: {
          data: data || context?.error,
          statusCode: context.response?.status,
        },
        response: context.response,
      };
    },
  },
  fetchOptions: {
    credentials: "include",
  },
});
