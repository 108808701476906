import { toRef } from "vue";

import { createEventHook } from "@vueuse/core";
import { useApi } from "@/services/api";
import { usePaymentsStore } from "@/stores/payments";

const mockupPaymentProfile = {
  id: "3390b6bc-80ab-4469-818e-7144b59c0c7a",
  card_number_first_six: "555555",
  card_number_last_four: "5557",
  payment_company_code: "mastercard",
  created_at: "2024-07-06T12:27:21-03:00",
};

export function usePaymentProfileService() {
  const store = usePaymentsStore();

  const fetchError = createEventHook<UnprocessedEntityError>();

  async function fetchPaymentProfile() {
    const { data, error } = useApi(
      "student/v1/me/subscriptions/payment_profiles"
    ).json<PaymentProfile[]>();

    // Only DEV
    // const data = toRef([mockupPaymentProfile]);
    // const error = toRef(null);

    if (!error.value) {
      data.value?.length && store.setPaymentProfile(data.value[0]);
    }

    return {
      data,
      error,
    };
  }

  async function updatePaymentProfile(newPaymentProfile: PaymentProfile) {
    const { data, error, isFetching } = await useApi(
      "student/v1/me/subscriptions/payment_profiles"
    )
      .post({
        payment_profile: newPaymentProfile,
      })
      .json<PaymentProfile[]>();

    if (!error.value) {
      await fetchPaymentProfile();
    } else {
      fetchError.trigger(error.value.data);
    }

    return {
      data,
      error,
      isFetching,
    };
  }

  return {
    store,
    fetchPaymentProfile,
    updatePaymentProfile,
    onPaymentProfileUpdateError: fetchError.on,
  };
}
