import { inject, provide, computed, toRef, type Ref, isRef } from "vue";

import { useApi } from "@/services/api";
import { useProductStore } from "@/stores/products";

export function useProducts() {
  const store = useProductStore();

  async function fetchAll() {
    if (store.data.length) {
      return {
        data: toRef(store.data),
        error: toRef(null),
        isFetching: toRef(false),
      };
    }

    const { data, error, isFetching } = await useApi("/app/v1/products")
      .get()
      .json<Product[]>();

    if (!error.value) {
      store.setProducts(data.value as Product[]);
    }

    return {
      data,
      error,
      isFetching,
    };
  }

  async function fetchFirst() {
    const { data } = await fetchAll();

    // NOTE: fetch first product
    const product = data.value?.find(() => true) || null;

    return product;
  }

  return {
    store,
    fetchFirst,
    fetchAll,
  };
}

export const ProductProvideKey = Symbol("product");

export function useProduct(data: Ref<Product | null> | Product | null) {
  const product = computed(() => (isRef(data) ? data.value : data));

  const annuallyPlan = computed(() =>
    product.value?.plans.find((p) => p.interval_count === 12)
  );

  const monthlyPlan = computed(() =>
    product.value?.plans.find((p) => p.is_recurring)
  );

  return {
    product,
    annuallyPlan,
    monthlyPlan,
  };
}

export function useProductInjectable() {
  return inject(ProductProvideKey) as ReturnType<typeof useProduct>;
}

export function provideProduct(product: Ref<Product | null> | Product | null) {
  const value = useProduct(product);
  provide(ProductProvideKey, value);
  return value;
}
