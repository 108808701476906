import { ref, computed } from "vue";
import { defineStore } from "pinia";

import uniq from "lodash/uniq";
import uniqBy from "lodash/uniqBy";

export const useCourseStorage = defineStore("courses", () => {
  const data = ref<Course[] | null>(null);

  const progress = ref<CourseModuleProgress[]>([]);
  const watched = ref<LessonLog[]>([]);
  const skipped = ref<LessonLog[]>([]);
  const likes = ref<LessonLog[]>([]);

  const areas = computed(() =>
    uniqBy(data.value?.map((course) => course.area), "slug")
  );

  const categories = computed(() => uniq(data.value?.flatMap((c) => c.tags)));

  function setCourses(courses: Course[]) {
    data.value = courses;
  }

  function setLikes(
    logs: CourseLessonLog[],
    type: "liked" | "disliked" = "liked"
  ) {
    const result = transformLog(logs, type);
    likes.value = [...new Set([...likes.value, ...result])];
  }

  function setWatched(
    logs: CourseLessonLog[],
    type: "watched" | "done" = "watched"
  ) {
    const result = transformLog(logs, type);
    const filteredResult = result.filter(
      (l) =>
        !watched.value.some(
          (s) =>
            s.lesson_module_slug === l.lesson_module_slug &&
            s.topic_slug === l.topic_slug &&
            s.lesson_slug === l.lesson_slug
        )
    );

    watched.value = [...new Set([...watched.value, ...filteredResult])];
  }

  function setSkipped(logs: CourseLessonLog[]) {
    const result = transformLog(logs, "skipped");
    const filteredResult = result.filter(
      (l) =>
        !skipped.value.some(
          (s) =>
            s.lesson_module_slug === l.lesson_module_slug &&
            s.topic_slug === l.topic_slug &&
            s.lesson_slug === l.lesson_slug
        )
    );

    skipped.value = [...new Set([...skipped.value, ...filteredResult])];
  }

  function setProgress(payload: CourseModuleProgress[]) {
    progress.value = payload;
  }

  function transformLog(
    logs: CourseLessonLog[],
    type: "watched" | "done" | "skipped" | "liked" | "disliked" | "noted"
  ) {
    return logs
      .flatMap((log) =>
        log.topics.map((t) => ({
          ...t,
          topic_slug: log.topic_slug,
          lesson_module_slug: log.lesson_module_slug,
        }))
      )
      .filter((topic) => topic.log_type === type)
      .map((topic) => ({
        lesson_module_slug: topic.lesson_module_slug,
        topic_slug: topic.topic_slug,
        lesson_slug: topic.lesson_slug,
        log_type: topic.log_type,
        percent: topic.percent,
      }));
  }

  return {
    data,
    areas,
    categories,
    likes,
    skipped,
    watched,
    progress,
    setCourses,
    setLikes,
    setWatched,
    setSkipped,
    setProgress,
  };
});
