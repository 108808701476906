export function darkenHexColor(hex: string, amount = 20) {
  // Remove o símbolo "#" se ele estiver presente
  hex = hex.replace(/^#/, "");

  // Divide a string em seus componentes R, G, B
  let num = parseInt(hex, 16);
  let r = (num >> 16) - amount;
  let g = ((num >> 8) & 0x00ff) - amount;
  let b = (num & 0x0000ff) - amount;

  // Garante que os valores fiquem dentro do intervalo 0-255
  r = Math.max(0, Math.min(255, r));
  g = Math.max(0, Math.min(255, g));
  b = Math.max(0, Math.min(255, b));

  // Converte de volta para hexadecimal
  let newHex = (r << 16) | (g << 8) | b;
  return `#${newHex.toString(16).padStart(6, "0")}`;
}
