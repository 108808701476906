import { ref } from "vue";
import { defineStore } from "pinia";

export const useGraduationStore = defineStore("graduations", () => {
  const data = ref<Graduation[]>([]);
  const me = ref<GraduationProgress[]>([]);

  function setGraduations(graduations: Graduation[]) {
    data.value = graduations;
  }

  function setMyGraduations(graduations: GraduationProgress[]) {
    me.value = graduations;
  }

  return {
    me,
    data,
    setGraduations,
    setMyGraduations,
  };
});
