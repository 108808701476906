<script setup lang="ts">
import { computed } from "vue";

import { useSiteStore } from "@/stores/site";
import { useCurrentUser } from "@/stores/user";
import { useGraduations } from "@/services/graduations";

import { darkenHexColor } from "@/utils/colors";

const props = defineProps({
  width: {
    type: Number,
    default: 40,
  },
  height: {
    type: Number,
    default: 40,
  },
});

const siteStore = useSiteStore();
const currentUser = useCurrentUser();

const outerWidth = computed(() => props.width + 2);
const outerHeight = computed(() => props.height + 2);

const flagColor = computed(() => {
  if (!currentUser.currentCourse) {
    return "#FAFAFA";
  }

  const { getMaxGraduationFromCourse } = useGraduations();
  const graduation = getMaxGraduationFromCourse(currentUser.currentCourse);

  switch (graduation?.graduation.degree) {
    case "first":
      return "#FAFAFA";
    case "second":
      return "#FFCD1A";
    case "third":
      return "#D32F2F";
    case "fourth":
      return "#00E676";
    case "fifth":
      return "#5E35B1";
    case "sixth":
      return "#795548";
    case "seventh":
      return "#212121";
    default:
      return "#FAFAFA";
  }
});

const flagColorDarken = computed(() => {
  return darkenHexColor(flagColor.value);
});
</script>

<template>
  <svg
    v-if="siteStore.isDarkMode"
    :width="outerWidth"
    :height="outerHeight"
    viewBox="0 0 54 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="54" height="54" rx="10.5" fill="#FFCD1A" />
    <mask
      id="mask0_1082_88640"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="54"
      height="54"
    >
      <rect width="54" height="54" rx="10.5" fill="#FFCD1A" />
    </mask>
    <g mask="url(#mask0_1082_88640)">
      <path
        d="M12.1434 54.9283C10.5686 54.9283 9.0556 54.6518 7.64932 54.1444C5.9734 53.5437 4.45103 52.6129 3.15523 51.4305C2.78259 51.091 2.42868 50.7305 2.09537 50.351C0.934396 49.0312 0.0205965 47.4806 -0.569253 45.7737C-1.06735 44.3413 -1.33887 42.8003 -1.33887 41.1963V13.7324H43.604V15.6091C43.5272 16.5322 43.3811 17.4324 43.1695 18.3097C42.7838 19.9328 42.1808 21.47 41.3925 22.8871H23.3993V22.91C17.2442 23.2857 12.34 28.3951 12.149 34.7118H12.1453V54.9283H12.1434Z"
        fill="#FF661A"
      />
      <path d="M7.64835 9.15464H-1.33984V0L7.64835 9.15464Z" fill="#FF661A" />
      <path d="M25.6269 0V9.15464H16.6387L25.6269 0Z" fill="#FF661A" />
      <path
        d="M12.1424 9.1543H-1.33984V13.7316H12.1424V9.1543Z"
        :fill="flagColor"
      />
      <path
        d="M25.6268 9.1543H12.1445V13.7316H25.6268V9.1543Z"
        :fill="flagColorDarken"
      />
      <path
        d="M43.6029 13.7324V15.6091C43.5262 16.5322 43.3801 17.4324 43.1685 18.3097C42.7828 19.9328 42.1798 21.47 41.3915 22.8871H35.7607V13.7324H43.6029Z"
        fill="#3F2214"
      />
      <path
        d="M16.2155 19.6031L16.1893 19.6298C15.8129 20.0131 15.2287 20.057 14.7886 19.7557C14.034 19.235 13.124 18.9317 12.1427 18.9317C11.1615 18.9317 10.2515 19.235 9.49684 19.7557C9.0568 20.0589 8.47069 20.0131 8.09618 19.6298L8.06997 19.6031C7.58685 19.111 7.65614 18.2909 8.21977 17.898C9.3358 17.1199 10.6878 16.6641 12.1427 16.6641C13.5977 16.6641 14.9478 17.1199 16.0657 17.898C16.6293 18.2909 16.6986 19.111 16.2155 19.6031Z"
        fill="#3F2214"
      />
      <path
        d="M41.3899 22.8867C40.7495 24.0501 39.9817 25.1315 39.1091 26.1156C38.6897 26.5886 38.2459 27.0406 37.7796 27.464C36.8152 28.349 35.7535 29.1233 34.615 29.7737C33.2237 30.5709 31.7144 31.1831 30.1209 31.5741C29.1303 31.8182 28.1061 31.9765 27.0556 32.0414C26.6885 32.0662 26.3197 32.0776 25.947 32.0776C25.8403 32.0776 25.7336 32.0757 25.6268 32.0738V41.196C25.6268 42.8 25.3553 44.341 24.8572 45.7733C24.2674 47.4803 23.3536 49.0308 22.1926 50.3506C21.8593 50.7302 21.5054 51.0906 21.1327 51.4301C19.8369 52.6126 18.3146 53.5433 16.6386 54.146C15.2342 54.6533 13.7212 54.928 12.1445 54.928V35.0929C12.1445 28.3509 17.5094 22.8867 24.1269 22.8867H41.3899Z"
        fill="#FFE9CF"
      />
    </g>
  </svg>

  <svg
    v-else
    xmlns="http://www.w3.org/2000/svg"
    :width="outerWidth"
    :height="outerHeight"
    :viewBox="`0 0 ${outerWidth} ${outerHeight}`"
    fill="none"
  >
    <rect y="0.75" :width="width" :height="height" rx="7.875" fill="#3255D1" />
    <mask
      id="mask0_659_1037"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      :width="outerWidth"
      :height="outerHeight"
    >
      <rect
        y="0.75"
        :width="width"
        :height="height"
        rx="7.875"
        fill="#3255D1"
      />
    </mask>
    <g mask="url(#mask0_659_1037)">
      <path
        d="M9.10781 41.9477C7.9267 41.9477 6.79194 41.7403 5.73724 41.3598C4.48029 40.9092 3.33851 40.2112 2.36667 39.3243C2.08719 39.0697 1.82176 38.7994 1.57177 38.5147C0.701041 37.5249 0.0156916 36.3619 -0.426696 35.0817C-0.800268 34.0075 -1.00391 32.8517 -1.00391 31.6487V11.0508H32.7032V12.4583C32.6456 13.1506 32.5361 13.8258 32.3774 14.4838C32.0881 15.7011 31.6359 16.854 31.0446 17.9168H17.5497V17.9339C12.9334 18.2157 9.25527 22.0478 9.11202 26.7853H9.10921V41.9477H9.10781Z"
        fill="#FF661A"
      />
      <path
        d="M5.73724 7.61598H-1.00391V0.75L5.73724 7.61598Z"
        fill="#FF661A"
      />
      <path d="M19.2216 0.75V7.61598H12.4805L19.2216 0.75Z" fill="#FF661A" />
      <path
        d="M9.10781 7.61719H-1.00391V11.0502H9.10781V7.61719Z"
        :fill="flagColorDarken"
      />
      <path
        d="M19.2211 7.61719H9.10938V11.0502H19.2211V7.61719Z"
        :fill="flagColor"
      />
      <path
        d="M32.7039 11.0508V12.4583C32.6463 13.1506 32.5368 13.8258 32.3781 14.4838C32.0888 15.7011 31.6366 16.854 31.0453 17.9168H26.8223V11.0508H32.7039Z"
        fill="#3F2214"
      />
      <path
        d="M12.1631 15.4504L12.1434 15.4704C11.8612 15.7579 11.423 15.7908 11.0929 15.5648C10.527 15.1743 9.84443 14.9469 9.10852 14.9469C8.37261 14.9469 7.69007 15.1743 7.1241 15.5648C6.79406 15.7922 6.35448 15.7579 6.0736 15.4704L6.05394 15.4504C5.6916 15.0813 5.74357 14.4662 6.16629 14.1716C7.00332 13.588 8.0173 13.2461 9.10852 13.2461C10.1997 13.2461 11.2123 13.588 12.0507 14.1716C12.4735 14.4662 12.5254 15.0813 12.1631 15.4504Z"
        fill="#3F2214"
      />
      <path
        d="M31.0434 17.9141C30.5631 18.7866 29.9873 19.5977 29.3328 20.3357C29.0182 20.6905 28.6854 21.0295 28.3357 21.3471C27.6124 22.0108 26.8161 22.5915 25.9622 23.0793C24.9188 23.6772 23.7868 24.1364 22.5917 24.4296C21.8487 24.6127 21.0805 24.7314 20.2927 24.78C20.0174 24.7986 19.7407 24.8072 19.4612 24.8072C19.3812 24.8072 19.3011 24.8058 19.2211 24.8044V31.646C19.2211 32.849 19.0175 34.0048 18.6439 35.079C18.2015 36.3592 17.5161 37.5222 16.6454 38.512C16.3954 38.7967 16.13 39.067 15.8505 39.3216C14.8787 40.2085 13.7369 40.9065 12.4799 41.3585C11.4266 41.739 10.2919 41.945 9.10938 41.945V27.0687C9.10938 22.0122 13.133 17.9141 18.0962 17.9141H31.0434Z"
        fill="#FFE9CF"
      />
    </g>
  </svg>
</template>
