import { ref } from "vue";
import { defineStore } from "pinia";
import { useStorage, useToggle, useDark } from "@vueuse/core";

export const useSiteStore = defineStore("site", () => {
  const pageTitle = ref();

  const courseSelectorVisible = ref(false);
  const isSubscriptionModalVisible = ref(false);
  const isDiscoveryPlatformModalVisible = ref(false);
  const isDarkMode = useStorage("isDark", false);

  const isDark = useDark({
    onChanged(dark: boolean) {
      isDarkMode.value = dark;
    },
    initialValue: isDarkMode.value ? "dark" : "light",
  });

  const toggleDark = useToggle(isDark);

  return {
    courseSelectorVisible,
    pageTitle,
    toggleDark,
    isSubscriptionModalVisible,
    isDiscoveryPlatformModalVisible,
    isDarkMode: isDarkMode,
  };
});
