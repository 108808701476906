import { useCourses } from "@/services/courses";
import { useCourseStorage } from "@/stores/courses";

// Middleware
// Este middleware é responsável por buscar os cursos do aluno
export default async () => {
  const { getStudentCourses } = useCourses();
  const { data: courses } = useCourseStorage();
  if (!courses?.length) await getStudentCourses();
};
