import { ref } from "vue";
import { defineStore, skipHydrate } from "pinia";
import { useStorage } from "@vueuse/core";

export const usePaymentsStore = defineStore("payments", () => {
  const data = useStorage<any[]>("payments", []);
  const paymentProfile = ref<PaymentProfile | undefined>(undefined);

  function setPaymentProfile(profile: PaymentProfile) {
    paymentProfile.value = profile;
  }

  return {
    paymentProfile,
    setPaymentProfile,
    data: skipHydrate(data),
  };
});
