import {
  type RouteLocationNormalized,
  type NavigationGuardNext,
} from "vue-router";

import { usePaymentsStore } from "@/stores/payments";
import { localeRootPath } from "@/services/router";

export default async (
  to: RouteLocationNormalized,
  _from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  if (to.name !== "checkout-success") return next();

  const paymentStore = usePaymentsStore();

  const payment = paymentStore.data.find(
    ({ plan, payment }) =>
      plan === to.params.id && payment.id === to.params.paymentId
  );

  if (!payment) return next(localeRootPath());

  next();
};
