import { computed } from "vue";
import { useFetch, createEventHook } from "@vueuse/core";

import omit from "lodash/omit";

import { useApi } from "@/services/api";

import { useCurrentUser } from "@/stores/user";

export function useProfile() {
  const currentUserStore = useCurrentUser();

  const updateProfileError = createEventHook<UnprocessedEntityError>();

  const currentUser = computed(() => currentUserStore.data);

  async function fetchProfile() {
    const { data, error, isFetching } = await useApi<UserProfile>(
      "/student/v1/me/profile"
    ).json();

    if (!error.value) {
      currentUserStore.data = data.value;
    }

    return {
      data,
      error,
      isFetching,
    };
  }

  async function updateProfile(data: UserProfile) {
    const sanitizedData = omit(data, [
      "id",
      "created_at",
      "updated_at",
      "gravatar",
      "name",
      "referral",
      "social",
      "selected_area",
    ]);

    const { error, isFetching, json, onFetchError } = useApi<UserProfile>(
      "/student/v1/me/profile"
    ).put({
      user: sanitizedData,
    });

    onFetchError(() => {
      updateProfileError.trigger(error.value.data);
    });

    await json();

    if (!error.value) {
      currentUserStore.data = Object.assign(
        {},
        currentUserStore.data,
        sanitizedData
      );
    }

    return {
      error,
      isFetching,
    };
  }

  async function updateProfileAvatar(file: File) {
    const formData = new FormData();
    formData.append("user[avatar]", file);

    const { error, isFetching, json, onFetchError } = useApi<UserProfile>(
      "/student/v1/me/profile"
    ).put(formData);

    onFetchError(() => {
      updateProfileError.trigger(error.value.data);
    });

    await json();

    if (!error.value) {
      await fetchProfile();
    }

    return {
      error,
      isFetching,
    };
  }

  async function logout() {
    await useFetch("/aprendiz/logout").delete();
    // confirmando logout do aluno
    window.location.assign("/aprendiz/login");
  }

  return {
    currentUser,
    fetchProfile,
    updateProfile,
    updateProfileAvatar,
    logout,
    onUpdateProfileError: updateProfileError.on,
  };
}
