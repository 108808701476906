<script setup lang="ts">
import { computed, type PropType } from "vue";
import { RouterLink } from "vue-router";

const props = defineProps({
  type: {
    type: String,
    default: "btn-default",
  },
  tag: {
    type: String as PropType<"button" | "a" | "router-link">,
    default: "button",
  },
  to: {
    type: [String, Object],
    default: undefined,
  },
  nativeType: {
    type: String as PropType<"button" | "submit" | "reset">,
    default: "button",
  },
  ghost: {
    type: Boolean,
    default: false,
  },
  outlined: {
    type: Boolean,
    default: false,
  },
  flat: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  category: {
    type: Boolean,
    default: false,
  },
});

const selectedTag = computed(() => {
  if (props.tag === "router-link") {
    return RouterLink;
  }

  return props.tag;
});
</script>

<template>
  <component
    :is="selectedTag"
    :type="nativeType"
    class="btn"
    :class="[
      type,
      {
        'btn-outline': outlined,
        'btn-flat ': flat,
        'btn-ghost': ghost,
        'btn-disabled': disabled,
        'btn-category': category,
      },
    ]"
    :to="to"
  >
    <slot></slot>
  </component>
</template>
