<script setup lang="ts">
import { type PropType } from "vue";

import { useField } from "vee-validate";

defineOptions({
  inheritAttrs: false,
});

const props = defineProps({
  as: {
    type: String as PropType<"input" | "textarea">,
    default: "input",
  },
  name: {
    type: String,
    required: true,
  },
  modelValue: {
    type: [String, Number],
    default: undefined,
  },
  label: {
    type: String,
    default: undefined,
  },
  placeholder: {
    type: String,
    default: "",
  },
  nativeType: {
    type: String,
    default: "text",
  },
  message: {
    type: String,
    default: "",
  },
  initialValue: {
    type: [String, Number],
    default: undefined,
  },
  rules: {
    type: [String, Object],
    default: undefined,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  tag: {
    type: String,
    default: "input",
  },
  maxlength: {
    type: Number,
    default: undefined,
  },
  hasError: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["input", "blur", "focus", "update:modelValue"]);

const { value, setValue, errorMessage } = useField(props.name, props.rules, {
  initialValue: props.initialValue,
  syncVModel: true,
});

function input(event: any) {
  const value = event.target?.value;

  setValue(value);

  emit("input", value);
}
</script>

<template>
  <div class="tj-text-input form-control" :class="{ 'has-error': hasError }">
    <label v-if="label" class="label">
      {{ label }}
    </label>
    <div class="relative">
      <slot name="icon-left"> </slot>
      <div class="field">
        <textarea
          v-if="props.as === 'textarea'"
          v-model="value"
          :name="name"
          class="textarea"
          :type="nativeType"
          :placeholder="placeholder"
          :disabled="disabled"
          :maxlength="maxlength"
          v-bind="$attrs"
          @input="input"
          @focus="$emit('focus', $event)"
          @blur="$emit('blur', $event)"
        />
        <input
          v-else
          v-model="value"
          :name="name"
          class="input"
          :type="nativeType"
          :placeholder="placeholder"
          :disabled="disabled"
          :maxlength="maxlength"
          v-bind="$attrs"
          @input="input"
          @focus="$emit('focus', $event)"
          @blur="$emit('blur', $event)"
        />
      </div>
      <slot name="icon-right"> </slot>
    </div>
    <div class="help">
      {{ message || errorMessage }}
    </div>
  </div>
</template>

<style scoped>
.textarea {
  @apply hover:bg-[#DBDFE7] focus:bg-[#EAECF0];
}

[data-theme="dark"] .textarea {
  @apply bg-[#282f3e] text-[#959EB1] hover:bg-[#3E4A63] focus:bg-[#282f3e];
}
</style>
