import {
  type RouteLocationNormalized,
  type NavigationGuardNext,
} from "vue-router";

import { useSubscriptions } from "@/services/subscriptions";

// Middleware:
// Este middleware utiliza meta title das rotas e os adiciona no title da página
export default async (
  to: RouteLocationNormalized,
  _from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  if (to.name !== "subscriptions") {
    return next();
  }

  const { fetchSubscriptions } = useSubscriptions();
  const { data, error } = await fetchSubscriptions();

  // Caso o aluno não possua nenhuma assinatura
  if (error.value || Number(data.value?.length) <= 0) {
    return next({ name: "profile-subscribe" });
  }

  next();
};
