<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    v-bind="$attrs"
  >
    <path
      d="M15.5582 14.5597C14.9186 15.6381 13.6764 16.2056 12.4426 15.983C11.2087 15.7605 10.243 14.7948 10.0205 13.5609C9.79795 12.3271 10.3654 11.0849 11.4438 10.4453"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18.9964 17.9985C17.2716 19.3068 15.1646 20.0118 12.9998 20.0053C9.41273 20.0691 6.09837 18.0977 4.44226 14.9151C3.84737 13.7093 3.84737 12.2954 4.44226 11.0895C5.27051 9.44017 6.59081 8.08891 8.22056 7.22266"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M21.4268 15.135C21.4669 15.059 21.5191 14.9908 21.5571 14.9133C22.152 13.7075 22.152 12.2935 21.5571 11.0877C19.901 7.90513 16.5866 5.93372 12.9995 5.9976C12.7746 5.9976 12.556 6.02761 12.334 6.04224"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M22.0032 21.0032L4.99609 3.99609"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
