import { ref } from "vue";
import { defineStore } from "pinia";

export const useProductStore = defineStore("products", () => {
  const data = ref<Product[]>([]);

  function setProducts(products: Product[]) {
    data.value = products;
  }

  return {
    data,
    setProducts,
  };
});
