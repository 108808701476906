import {
  type RouteLocationNormalized,
  type NavigationGuardNext,
} from "vue-router";

import { useCurrentUser } from "@/stores/user";
import { localeRootPath } from "@/services/router";

// Middleware:
// Caso aluno não tenha passado pelos primei
export default (
  to: RouteLocationNormalized,
  _from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const currentUserStore = useCurrentUser();

  if (to.meta.checkout) {
    return next();
  }

  // Caso aluno não tenha escolhido como irá utilizar a plataforma e o fluxo não seja o fluxo inicial
  if (!currentUserStore.configured && !to.meta.start) {
    return next({ name: "start-index" });
  }

  if (to.name === "welcome") {
    return next(localeRootPath());
  }

  return next();
};
