import {
  type RouteLocationNormalized,
  type NavigationGuardNext,
} from "vue-router";

import { useProfile } from "@/services/profile";
import { useProducts } from "@/services/products";
import { useSubscriptions } from "@/services/subscriptions";
import { usePaymentProfileService } from "@/services/payment-profile";
import { useGraduations } from "@/services/graduations";

// Middleware
// Este middleware é responsável por buscar o perfil do aluno
export default async (
  _to: RouteLocationNormalized,
  _from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const { fetchProfile } = useProfile();
  const { fetchPaymentProfile } = usePaymentProfileService();
  const { fetchGraduations, fetchMeGraduations } = useGraduations();
  const { fetchSubscriptions } = useSubscriptions();
  const { fetchAll: fetchProducts } = useProducts();

  // Carregando dados do perfil do aluno
  await fetchProfile();
  // Carregando dados do perfil de pagamento do aluno
  await fetchPaymentProfile();

  // Carregando dados das assinaturas do aluno
  await fetchSubscriptions();

  // Carregando dados das graduações (faixas) do aluno
  await fetchGraduations();
  await fetchMeGraduations();

  // Carregando produtos e planos
  await fetchProducts();

  next();
};
