import { inject, computed, type Ref, isRef, toRef } from "vue";
import { useApi } from "@/services/api";

import { useCourseStorage } from "@/stores/courses";

export function useCourses() {
  const store = useCourseStorage();

  async function getStudentCourses() {
    const { setCourses } = useCourseStorage();

    if (store.data?.length) {
      return {
        data: toRef(store.data),
        error: toRef(null),
        isFetching: toRef(false),
      };
    }

    const { data, error, isFetching } = await useApi("student/v1/courses")
      .get()
      .json<Course[]>();

    if (!error.value) {
      setCourses(data.value as Course[]);
    }

    return {
      data,
      error,
      isFetching,
    };
  }

  function fetchCourse(id: string, url?: Ref<string>) {
    const courseId = isRef(id) ? id.value : id;

    const { data, error, execute, isFetching } = useApi(
      url || `student/v1/courses/${courseId}`
    )
      .get()
      .json<CourseComplete>();

    return {
      data,
      error,
      refresh: execute,
      isFetching,
    };
  }

  async function fetchCourseSync(id: string, url?: Ref<string>) {
    const courseId = isRef(id) ? id.value : id;

    const { data, error, execute, isFetching } = await useApi(
      url || `student/v1/courses/${courseId}`
    )
      .get()
      .json<CourseComplete>();

    return {
      data,
      error,
      refresh: execute,
      isFetching,
    };
  }

  const areas = computed(() => store.areas);

  return {
    store,
    areas,
    getStudentCourses,
    fetchCourse,
    fetchCourseSync,
  };
}

export const CourseProvideKey = Symbol("course");
export const CoursesProvideKey = Symbol("courses");

export function useCourse(
  inputCourse: Ref<Course | CourseComplete | null> | Course | CourseComplete,
  isFetching: Ref<boolean> = computed(() => false)
) {
  const store = useCourseStorage();
  const course = computed(
    () =>
      (isRef(inputCourse) ? inputCourse.value : inputCourse) as CourseComplete
  );

  const professor = computed(() => {
    return course.value?.professors[0];
  });

  const lessonModules = computed(() => course.value?.lesson_modules);

  const topics = computed(() => {
    return course.value?.lesson_modules.flatMap((cm) => cm.topics);
  });

  const totalVideos = computed(() => {
    return topics.value
      .flatMap((t) => t.lessons)
      .filter((l) => l.type === "video").length;
  });

  const totalQuizes = computed(() => {
    return topics.value
      .flatMap((t) => t.lessons)
      .filter((l) => l.type === "quiz").length;
  });

  const totalTasks = computed(() => {
    return topics.value
      .flatMap((t) => t.lessons)
      .filter((l) => l.type === "activity" || l.type === "final_project").length;
  });

  const totalProgressLessons = computed(() => {
    return store.progress
      .flatMap((p) =>
        p.topics.reduce((acc, t) => acc + t.topic_total_lessons, 0)
      )
      .reduce((acc, t) => acc + t, 0);
  });

  const totalProgressLessonsWatched = computed(() => {
    return store.progress
      .flatMap((p) =>
        p.topics.reduce((acc, t) => acc + t.topic_total_lessons_done, 0)
      )
      .reduce((acc, t) => acc + t, 0);
  });

  const percentageWatched = computed(() => {
    const result =
      (Number(totalProgressLessonsWatched.value) /
        Number(totalProgressLessons.value)) *
      100;
    return Number(result.toFixed(2));
  });

  const totalModules = computed(() => {
    return course.value?.total_lesson_modules;
  });

  async function fetchModulesProgress(courseId: string) {
    const { data, error, isFetching } = await useApi(
      `student/v1/me/courses/${courseId}/lessons/progress`
    )
      .get()
      .json<CourseModuleProgress[]>();

    if (!error.value && data.value) {
      store.setProgress(data.value);
    }

    return {
      data,
      error,
      isFetching,
    };
  }

  async function fetchLessonsProgress(courseId: string) {
    const { data, error, isFetching } = await useApi(
      `student/v1/me/courses/${courseId}/lessons/watched`
    )
      .get()
      .json<CourseLessonLog[]>();

    if (!error.value && data.value) {
      store.setWatched(data.value);
      store.setSkipped(data.value);
    }

    return {
      data,
      error,
      isFetching,
    };
  }

  async function fetchLikes(courseId: string) {
    const { data, error, execute, isFetching } = await useApi(
      `student/v1/me/courses/${courseId}/lessons/liked`
    )
      .get()
      .json<CourseLessonLog[]>();

    if (!error.value && data.value) {
      store.setLikes(data.value, "liked");
      store.setLikes(data.value, "disliked");
    }

    return {
      data,
      error,
      refresh: execute,
      isFetching,
    };
  }

  async function fetchTasks(courseId: string) {
    const { data, error, execute, isFetching } = await useApi(
      `student/v1/courses/${courseId}/tasks`
    )
      .get()
      .json<{ lesson_modules: TaskItem[] }>();

    return {
      data,
      error,
      refresh: execute,
      isFetching,
    };
  }

  async function fetchTasksProgress(courseId: string) {
    const { data, error, isFetching } = await useApi(
      `student/v1/me/courses/${courseId}/tasks/done`
    )
      .get()
      .json<CourseLessonLog[]>();

    if (!error.value && data.value) {
      store.setWatched(data.value, "done");
      store.setSkipped(data.value);
    }

    return {
      data,
      error,
      isFetching,
    };
  }

  async function fetchQuizzesProgress(courseId: string) {
    const { data, error, isFetching } = await useApi(
      `student/v1/me/courses/${courseId}/quizzes/done`
    )
      .get()
      .json<CourseLessonLog[]>();

    if (!error.value && data.value) {
      store.setWatched(data.value, "done");
      store.setSkipped(data.value);
    }

    return {
      data,
      error,
      isFetching,
    };
  }

  function getCourseModuleByTopic(
    courseModules: LessonModule[],
    topic: CourseTopic
  ) {
    return courseModules.find((module) => {
      return module.topics.some((t) => t.slug === topic.slug);
    });
  }

  function isModuleCompleted(module: LessonModule) {
    const totalLessons = store.progress
      .filter((p) => p.lesson_module_slug === module.slug)
      .flatMap((p) => p.topics)
      .reduce((acc, t) => acc + t.topic_total_lessons, 0);

    const totalWatched = store.progress
      .filter((p) => p.lesson_module_slug === module.slug)
      .flatMap((p) => p.topics)
      .reduce((acc, t) => acc + t.topic_total_lessons_done, 0);

    return totalLessons > 0 && totalWatched >= totalLessons;
  }

  function courseTopicIndex(lessonModule: LessonModule, topic: CourseTopic) {
    const topics = lessonModule.topics;

    if (!topics.length) {
      return -1;
    }

    return topics.findIndex((t) => topic.slug === t.slug);
  }

  function nextTopic(lessonModule: LessonModule, topic: CourseTopic) {
    const topicIndex = courseTopicIndex(lessonModule, topic);

    if (topicIndex < lessonModule.topics.length - 1) {
      return lessonModule.topics[topicIndex + 1];
    }
  }

  function prevTopic(lessonModule: LessonModule, topic: CourseTopic) {
    const topicIndex = courseTopicIndex(lessonModule, topic);

    if (topicIndex > 0) {
      return lessonModule.topics[topicIndex - 1];
    }
  }

  function getTotalModuleLessons(module: LessonModule) {
    const moduleProgress = store.progress.find(
      (p) => p.lesson_module_slug === module.slug
    );
    return (
      moduleProgress?.topics.reduce(
        (acc, t) => acc + t.topic_total_lessons,
        0
      ) || 0
    );
  }

  function getTotalWatchedModuleLessons(module: LessonModule) {
    const moduleProgress = store.progress.find(
      (p) => p.lesson_module_slug === module.slug
    );

    return (
      moduleProgress?.topics.reduce(
        (acc, t) => acc + t.topic_total_lessons_done,
        0
      ) || 0
    );
  }

  async function restartTrip() {
    const courseId = course.value.slug;

    const { data, error, isFetching } = await useApi(
      `student/v1/me/courses/${courseId}/progress/reset`
    ).put();

    return {
      data,
      error,
      isFetching,
    };
  }

  return {
    course,
    store,
    professor,
    topics,
    totalModules,
    totalProgressLessons,
    totalProgressLessonsWatched,
    percentageWatched,
    totalVideos,
    totalQuizes,
    totalTasks,
    lessonModules,
    fetchModulesProgress,
    fetchLessonsProgress,
    fetchLikes,
    fetchTasks,
    fetchTasksProgress,
    fetchQuizzesProgress,
    getCourseModuleByTopic,
    courseTopicIndex,
    nextTopic,
    prevTopic,
    getTotalModuleLessons,
    getTotalWatchedModuleLessons,
    restartTrip,
    isModuleCompleted,
    isFetching,
  };
}

export function useCourseInjectable() {
  return inject(CourseProvideKey) as ReturnType<typeof useCourse>;
}

export function useCoursesInjectable() {
  return inject(CoursesProvideKey) as Ref<Course[]>;
}
