import { useRoute, type RouteLocationRaw } from "vue-router";
import { useCurrentUser } from "@/stores/user";

export function isRouteStart(name: string) {
  const route = useRoute();
  return route.matched.some((record) => record.name === name);
}

export function localeLessonPath(
  lessonId: string,
  courseId?: string,
  lessonModuleId?: string,
  topicId?: string
) {
  const currentUser = useCurrentUser();
  const name =
    currentUser.platformMode === "dashboard"
      ? "dashboard-lesson"
      : "trip-lesson";

  if (courseId) {
    return {
      name,
      params: {
        id: courseId,
        lessonModuleId: lessonModuleId,
        topicId: topicId,
        lessonId,
      },
    } as RouteLocationRaw;
  }

  return {
    name,
    params: {
      lessonId,
    },
  } as RouteLocationRaw;
}

export function localeRootPath() {
  const currentUser = useCurrentUser();
  const name =
    currentUser.platformMode === "dashboard" ? "dashboard-index" : "trip-index";

  return {
    name,
  } as RouteLocationRaw;
}
