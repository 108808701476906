import { ref, computed } from "vue";
import { defineStore, skipHydrate } from "pinia";
import { useStorage } from "@vueuse/core";

export const useCurrentUser = defineStore("user", () => {
  const data = ref<UserProfile | null>(null);
  const subscriptions = ref<Subscription[]>([]);

  const platformMode = useStorage<"trip" | "dashboard">("platformMode", null);
  const category = useStorage<string>("category", "");
  const area = useStorage<CourseArea | null>("tripArea", Object);

  const currentCourse = useStorage<Course | CourseComplete | null>(
    "tripCourse",
    Object
  );

  const currentModule = useStorage<LessonModule | null>("tripModule", Object);
  const currentTopic = useStorage<CourseTopic | null>("tripTopic", Object);

  const dictionaryModulesVisits = useStorage<any>("registerModules", Object);
  const dictionaryTopicsVisits = useStorage<any>("registerTopics", Object);

  const fullName = computed(
    () => data.value?.first_name + " " + data.value?.last_name
  );

  const profileAvatar = computed(() => data.value?.gravatar);

  const configured = computed(() => {
    if (platformMode.value === "trip") {
      return !!area.value && !!currentCourse.value;
    }

    return !!platformMode.value;
  });

  const hasActivePlan = computed(() => {
    return subscriptions.value.some(
      (subscription) => subscription.status === "active"
    );
  });

  function setSubscriptions(subs: Subscription[]) {
    subscriptions.value = subs;
  }

  function setPlatformMode(mode: "trip" | "dashboard") {
    platformMode.value = mode;
  }

  function setArea(areaSlug: CourseArea) {
    area.value = areaSlug;
  }

  function setCategory(tag: string) {
    category.value = tag;
  }

  function setCourse(course: Course | CourseComplete | null) {
    currentCourse.value = course;
  }

  function setCurrentModule(module: LessonModule | null) {
    currentModule.value = module;
  }

  function setCurrentTopic(module: CourseTopic | null) {
    currentTopic.value = module;
  }

  function registerTopicsVisits(moduleSlug: string, topicSlug: string) {
    dictionaryTopicsVisits.value[moduleSlug] = topicSlug;
  }

  function registerModulesVisits(
    courseSlug: string,
    lessonModule: LessonModule
  ) {
    dictionaryModulesVisits.value[courseSlug] = {
      slug: lessonModule.slug,
      position: lessonModule.position,
    };
  }

  return {
    data,
    subscriptions,
    fullName,
    configured,
    profileAvatar,
    area: skipHydrate(area),
    category: skipHydrate(category),
    platformMode: platformMode,
    currentCourse: skipHydrate(currentCourse),
    currentModule: skipHydrate(currentModule),
    currentTopic: skipHydrate(currentTopic),
    dictionaryTopicsVisits: skipHydrate(dictionaryTopicsVisits),
    dictionaryModulesVisits: skipHydrate(dictionaryModulesVisits),
    setPlatformMode,
    setArea,
    setCategory,
    setCourse,
    setCurrentModule,
    setCurrentTopic,
    setSubscriptions,
    registerTopicsVisits,
    registerModulesVisits,
    hasActivePlan,
  };
});
