<script setup lang="ts">
import { ref } from "vue";

import Container from "@/components/base/Container.vue";
import Button from "@/components/base/Button.vue";
import TextInput from "@/components/base/TextInput.vue";

import IconEmail from "@/components/icons/IconEmail.ce.vue";
import EyeSlash from "@/components/icons/EyeSlash.vue";

const showPassword = ref(false);

function changePasswordVisibility(visibility: boolean) {
  showPassword.value = visibility;
}
</script>

<template>
  <div class="min-h-screen pt-8">
    <Container class="mt-8">
      <h1 class="text-4xl font-extrabold my-5">Componentes</h1>
      <hr class="my-5" />

      <h2 class="text-3xl font-bold my-5">Buttons</h2>

      <div class="flex flex-wrap gap-2 my-5">
        <Button type="btn-primary"> Primary </Button>
        <Button type="btn-error"> Danger </Button>
        <Button type="btn-success"> Success </Button>
        <Button type="btn-success" :disabled="true"> Disabled </Button>
      </div>

      <div class="flex flex-wrap gap-2 my-5">
        <Button type="btn-primary" :outlined="true"> Primary </Button>
        <Button type="btn-error" :outlined="true"> Danger </Button>
        <Button type="btn-success" :outlined="true"> Success </Button>
        <Button type="btn-neutral" :outlined="true"> Neutral </Button>
        <Button type="btn-success" :outlined="true" :disabled="true">
          Disabled
        </Button>
      </div>

      <div class="flex flex-wrap gap-2 my-5">
        <Button type="btn-primary" :flat="true"> Primary </Button>
        <Button type="btn-error" :flat="true"> Danger </Button>
        <Button type="btn-success" :flat="true"> Success </Button>
        <Button type="btn-neutral" :flat="true"> Neutral </Button>
        <Button type="btn-neutral" :flat="true" :disabled="true">
          Disabled
        </Button>
      </div>

      <h2 class="text-3xl font-bold my-5">Inputs</h2>
      <div class="my-5">
        <TextInput
          name="name"
          label="Nome Completo"
          placeholder="Nome Completo"
          class="w-full max-w-sm"
        />
      </div>
      <div class="my-5">
        <TextInput
          name="email"
          label="E-mail"
          :native-type="showPassword ? 'text' : 'password'"
          :has-error="true"
          message="Este campo é obrigatório"
          class="w-full max-w-sm"
        >
          <template #icon-left>
            <div class="icon is-left">
              <IconEmail width="24" />
            </div>
          </template>
          <template #icon-right>
            <div class="icon is-right">
              <EyeSlash
                width="24"
                @click="changePasswordVisibility(!showPassword)"
                @blur="changePasswordVisibility(false)"
                class="hover:cursor-pointer outline-none"
              />
            </div>
          </template>
        </TextInput>
      </div>
    </Container>
  </div>
</template>
